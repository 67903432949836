/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: JS Scripts
	Author:Shane Prendergast
	Author URL:http://www.webknit.co.uk
	Template URL:http://base.webknit.co.uk/
*/

// JS EXAMPLE

var FF = FF || {};

FF.navigation = function() {
	
	var navBtn = $('.nav-btn');
	var nav = $('.nav');

	function init() {

		navBtn.click(toggleNav);

	}

	function toggleNav() {

		navBtn.toggleClass('nav-btn--active');
		nav.fadeToggle();

	}

	init();
	
};

FF.tabBox = function() {

	var d = new Date();
	var weekday = new Array(7);
	weekday[0]=  "sun";
	weekday[1] = "mon";
	weekday[2] = "tue";
	weekday[3] = "wed";
	weekday[4] = "thr";
	weekday[5] = "fri";
	weekday[6] = "sat";

	var n = weekday[d.getDay()];
	
	var tabBtn = $('.tabs__li');
	var tabContent = $('.tab-content');

	function init() {

		tabContent.removeClass('tab-content--active');
		tabBtn.removeClass('tabs__li--active');

		$('.tabs__li--' + n).addClass('tabs__li--active');
		$('#' + n).addClass('tab-content--active');

		tabBtn.click(changeTab);

	}

	function changeTab() {

		var boxName = $(this).data('box');
		tabContent.removeClass('tab-content--active');
		tabBtn.removeClass('tabs__li--active');

		$(this).addClass('tabs__li--active');
		$('#' + boxName).addClass('tab-content--active');

	}

	init();
	
};

new FF.navigation();
new FF.tabBox();

