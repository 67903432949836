/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Dev.js
	Author:Shane Prendergast
	Author URL:http://www.webknit.co.uk
	Template URL:http://base.webknit.co.uk/
	
	You can read more abotu this file - http://shaneprendergast.co.uk/web-design/dev-js/
*/

